<template>
  <div id="UploadSupplier">
    <v-container>
      <v-card class="secondary px-10 py-5">
        <v-card-title class="headline mb-5">Upload Master Produk</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="masterProductSelect"
                  :items="masterProductList"
                  item-text="master_name"
                  item-value="master_code"
                  label="Masukkan Master Produk*"
                  :rules="masterRules"
                  required
                ></v-select>
                <v-select
                  v-model="supplierSelect"
                  :items="supplierList"
                  item-value="supplier_code"
                  label="Masukkan Supplier*"
                  :rules="supplierRules"
                  required
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.supplier_name }} -
                    {{ data.item.supplier_code }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.supplier_name }} -
                    {{ data.item.supplier_code }}
                  </template>
                </v-select>

                <v-file-input
                  show-size
                  label="Masukkan File Excel*"
                  accept=".xlsx, .xls"
                  v-model="file"
                  :rules="fileRules"
                  @keydown.enter="submitData"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-responsive
                  class="overflow-auto"
                  max-height="calc(90vh - 150px)"
                >
                  <xlsx-read :file="file">
                    <xlsx-table />
                  </xlsx-read>
                </v-responsive>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  :disabled="loading"
                  color="success"
                  class="mt-3 align-center"
                  @click="submitData"
                  :loading="loading"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { XlsxRead, XlsxTable } from "vue-xlsx/dist/vue-xlsx.es.js";
import CommonService from "@/services/CommonService";
import ProductService from "@/services/ProductService";

export default {
  components: {
    XlsxRead,
    XlsxTable
  },
  props: { shown: { type: Boolean, default: false } },

  data: () => ({
    file: null,
    loading: false,
    supplierList: [],
    supplierSelect: "",
    masterProductSelect: "",
    valid: true,
    masterProductList: [
      { master_name: "Master Produk Lajual", master_code: "0" },
      { master_name: "Master Produk Supplier", master_code: "1" }
    ],
    masterRules: [v => !!v || "Master Produk wajib diisi"],
    supplierRules: [v => !!v || "Supplier wajib diisi"],
    fileRules: [v => !!v || "File wajib diisi"]
  }),

  mounted() {
    this.getSupplier();
  },

  methods: {
    getSupplier() {
      CommonService.getSupplier()
        .then(response => {
          this.supplierList = response.data.suppliers;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    submitData() {
      this.$refs.form.validate();

      let fileName = this.file.name;
      let ext = fileName.split(".").pop();

      if (ext === "xlsx" || ext === "xls") {
        let formData = new FormData();
        let file = this.file;
        formData.append("supplier_code", this.supplierSelect);
        formData.append("prodmast_file", file);

        if (this.masterProductSelect === "0") {
          this.loading = true;

          ProductService.uploadProductLajual(formData)
            .then(response => {
              this.loading = false;

              if (response.data.status === 0) {
                this.$tostini({
                  message: response.data.message,
                  type: "error",
                  timeout: 3000
                });
              } else {
                this.$tostini({
                  message: "Berhasil Upload",
                  type: "success"
                });
              }
            })
            .catch(() => {
              this.loading = false;

              this.$tostini({
                message: "Gagal Upload!",
                type: "error"
              });
            });
        } else {
          this.loading = true;

          ProductService.uploadProductSupplier(formData)
            .then(response => {
              if (response.data.status === 0) {
                this.$tostini({
                  message: response.data.message,
                  type: "error"
                });
              } else {
                this.$tostini({
                  message: "Berhasil Upload",
                  type: "success"
                });
              }
            })
            .catch(() => {
              this.$tostini({
                message: "Gagal Upload!",
                type: "error"
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.$tostini({
          message: "Format File yang anda masukkan salah!",
          type: "error"
        });
      }
    },
    onCancel() {
      this.$emit("cancel");
    }
  },
  watch: {
    shown() {
      this.rules = {};
      this.$refs.form.reset();
    }
  }
};
</script>

<style>
#UploadSupplier table {
  border-collapse: collapse;
  width: 100%;
}

#UploadSupplier table td {
  border: 1px solid #ddd;
  padding: 8px;
}

#UploadSupplier table tr:first-child {
  text-align: center;
  font-weight: bold;
  background-color: #f94144;
  color: white;
}

#UploadSupplier table td:first-child {
  width: 5%;
  text-align: center;
}
</style>
