import api from "@/services/ApiService";

export default {
  uploadProductLajual(params) {
    return api().post(`/admin/uploadprodmast`, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  uploadProductSupplier(params) {
    return api().post(`/admin/uploadprodmast_supplier`, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
};
